.navContainer {
    /* background-image: ; */
    padding: 10px;
    font-size: 16px;
    padding-left: 24px;
    padding-right: 24px;
    transition: .3s ease-in;
    /* box-shadow: 2px 2px 5px black; */
}



.active {
    background-color: #000;
}

@media (max-width: 769px) {
    .navbar-collapse {
        margin-top: 4px;
        background-color: black;
        padding-bottom: 8px;
        border-radius: 8px;
    }
}

.RowsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.firstRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media (max-width:1200px) {
    .navContainer {
        font-size: 14px;
    }
}

.linkContainer {
    text-transform: capitalize;
}


.logo {
    margin-right: 0;
    width: 180px;
    height: 30px;
    color: white;
    font-weight: 700;
    background-image: url('../../assets/images/mysmartbookieP2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.navLink {
    color: black !important;
    font-weight: 500;
}

.toggleButton {
    border-radius: 8;
    padding: 8px 8px 0px 8px;
    border: none;
    width: 45px;
    height: 45px;
    aspect-ratio: 1/1;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('../../assets/images/hamburgerMenu.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 25px;
    height: 25px;
}


.navLink2 {
    color: white !important;
    font-weight: 500;
    padding-top: 6px;
    /* margin-left: 16px; */
}

.upcomingGames {
    color: #55e6c1 !important;
    font-weight: 600;
    padding-top: 6px;

}

.navLink22 {
    color: white !important;
    font-weight: 500;
    /* margin-right: 32px; */
    margin-left: 8px;
    padding-top: 6px;

}

@media (max-width: 762px) {
    .navLink2 {
        margin-left: 8px;
    }
}

@media (min-width: 762px) and (max-width:820px) {
    .navLink22 {
        margin-right: 0px;
    }
}

.sportDropdown {
    color: white;
    border: none;
}

.sportDropdown:focus {
    outline: none;
    box-shadow: none;
}

.vl {
    width: 2px;
    height: 30px;
    background-color: white;

}