@font-face {
    font-family: Montserrat;
    src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf);
}



* {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
}


body {
    background-color: #000000;
}

body::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.ButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -24px;
}

.CarouselTextContainer {
    position: relative;
    /* text-align: center; */
    color: black;
}

.centered {
    position: absolute;
    top: 13%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}