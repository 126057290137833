.typesBadge p {
    margin: 0;
    letter-spacing: 0.05rem;
    padding: 8px 16px 8px 16px;
    color: white;
}

.types {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    display: flex;
    flex-direction: row;

}

.types::-webkit-scrollbar {
    display: none;
}


@media (max-width:1500px) {
    .types {
        margin-top: 16px;

    }
}

@media (max-width:985px) {
    .types {
        margin-top: 32px;
    }
}


.types .typesBadge {
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    background-color: #000;
    opacity: 0.5;
    border-radius: 8px;
    margin-left: 4px;
    margin-top: 8px;

}