.tableHead {
     background: #3a3a3c;
     border-bottom-style: hidden;
     color: #ffffff;
}

.tableCol {
     background-color: gray;
}

.gameTableContainer {
     max-height: 40vh;
     /* height: 40vh; */
     overflow-y: auto;
     padding-right: 0
}

.gameTableContainer::-webkit-scrollbar {
     display: none;
     /* for Chrome, Safari, and Opera */
}