.newsContainer {
    overflow-x: scrollpx;
    display: flex;
    flex-direction: row;
}

.mobileNewsContainer {
    overflow-x: scroll;
    display: flex;
    text-align: center;
    flex-direction: column;
    /* height: 50vh; */
}

.newsContainer::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.newsTitle {
    font-size: 16px;
    margin-bottom: 12px;
    line-height: 1.3px;
    height: 42px;
    text-align: justify;
}

.newsDetailsContainer {
    width: 500px;
    background-color: #222222;
    margin-left: 8px;
    padding: 8px;
    border-radius: 16px;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.newsTextContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    /* margin: 8px 0px 8px 0px; */
}

.newsImageContainer {
    border-radius: 12px;
    width: 100px;
    height: 100px;
    aspect-ratio: 1/1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}