.linksContainer {
    color: white;
    /* text-align: center; */
    margin-top: 32px;
    margin-left: 16px
}

.verticalLine {
    width: 100%;
    height: 3px;
    background-color: white;
    opacity: 8%;

}

.copyRight {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: black;
    height: 40px;
}