.Buttons {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 0;
    width: 100px;
    /* height: 30px; */
    font-size: 14px;
    border-radius: 100px;
    margin-left: 4px;
    text-transform: uppercase;
    font-weight: 600;
}