.date {
    background-color: none;
    /* margin-top: 16; */
    height: 40px;
    width: 100%;
    /* border: 1px solid #c4c4c4; */
    border-radius: 5px;
    padding-left: 8px;
    color: #7e7b8b;
    cursor: pointer
}

.date:focus {
    color: white
}

.PhoneInput {
    width: 104%;
    height: 40px;
    /* border: 1px solid #c4c4c4; */
    padding: 8px;
    border-radius: 5px;
}

.PhoneInputInput {
    background-color: #424242;
    border: none;
}

.border-radius-2 .modal-content {
    border-radius: 8px;
    background-color: #222222;
    color: white;
    /* border: none; */
}

.modal-header {
    width: 100.1%;
    height: 74px;
    background-color: #a55eea;
    color: white;
    border-bottom: none;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input {
    background-color: #424242;
    border: none;
    border-radius: 8px;
    padding-left: 6px;
}

.input:focus {
    background-color: #424242;
    color: white;
}

.register {
    margin-top: 24px;
    width: 100%;
    background-color: #a55eea;
    border: none;
    padding: 8px;
    border-radius: 8px;
    color: white
}

.register:hover {
    background-color: #a55eea;

}